import Cookies from 'js-cookie'
import Config from '@/settings'

const TokenKey = 'qy_reimbursement_token'
const LonginStatusKey = 'qy_login_status'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token, rememberMe) {
  if (rememberMe) {
    return Cookies.set(TokenKey, token, { expires: Config.tokenCookieExpires })
  } else {
    return Cookies.set(TokenKey, token)
  }
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getLoginStatus() {
  return Cookies.get(LonginStatusKey)
}

export function setLoginStatus(loginStatus) {
  return Cookies.set(LonginStatusKey, loginStatus)
}

export function removeLoginStatus() {
  return Cookies.remove(LonginStatusKey)
}
