import Vue from 'vue'
// 1.Tabbar
import {
  Tabbar,
  TabbarItem
} from 'vant'
Vue.use(Tabbar).use(TabbarItem)

// 2.loading组件
import {
  Loading
} from 'vant'
Vue.use(Loading)

// 3.栅栏格子
import {
  Grid,
  GridItem
} from 'vant'
Vue.use(Grid).use(GridItem)

// 4.倒计时
import {
  CountDown
} from 'vant'
Vue.use(CountDown)

// 5.图片懒加载
import {
  Lazyload
} from 'vant'
// options 为可选参数，无则不传
Vue.use(Lazyload)

// 6.Tabbar 标签栏
import {
  Tab,
  Tabs
} from 'vant'
Vue.use(Tab).use(Tabs)

// 7.分割线
import {
  Divider
} from 'vant'
Vue.use(Divider)

// 8.轻弹窗
import {
  Toast
} from 'vant'
Vue.use(Toast)

// 9.导航栏
import {
  NavBar
} from 'vant'
Vue.use(NavBar)

// 10.提交订单
import {
  SubmitBar
} from 'vant'
Vue.use(SubmitBar)

// 11.复选框
import {
  Checkbox,
  CheckboxGroup
} from 'vant'
Vue.use(Checkbox).use(CheckboxGroup)

// 12.提示框
// import {
//     Dialog
// } from 'vant';
// Vue.use(Dialog)

// 13.单元格
import {
  Cell,
  CellGroup
} from 'vant'
Vue.use(Cell).use(CellGroup)

// 14.tag标记
import {
  Tag
} from 'vant'
Vue.use(Tag)

// 15.填写订单
import {
  ContactCard,
  ContactList,
  ContactEdit
} from 'vant'
Vue.use(ContactCard).use(ContactList).use(ContactEdit)

// 16.icon图标
import {
  Icon
} from 'vant'
Vue.use(Icon)

// 17.单选框及单选框组
import {
  RadioGroup,
  Radio
} from 'vant'
Vue.use(RadioGroup)
Vue.use(Radio)

// 18.switch 开关
import {
  Switch
} from 'vant'
Vue.use(Switch)

// 19.field输入框
import {
  Field
} from 'vant'
Vue.use(Field)

// 20.联系地址
import {
  AddressList
} from 'vant'
Vue.use(AddressList)

// 21.地址编辑
import {
  AddressEdit
} from 'vant'
Vue.use(AddressEdit)

// 22.省市区选择器
import {
  Area
} from 'vant'
Vue.use(Area)

// 23.弹出层
import {
  Popup
} from 'vant'
Vue.use(Popup)

// 24.滑动单元格
import {
  SwipeCell
} from 'vant'
Vue.use(SwipeCell)

// 25.van Button
import {
  Button
} from 'vant'
Vue.use(Button)

// 26.时间选择
import {
  DatetimePicker
} from 'vant'
Vue.use(DatetimePicker)

// 27.优惠券
import {
  CouponCell,
  CouponList
} from 'vant'
Vue.use(CouponCell).use(CouponList)

// 28.粘性布局
import {
  Sticky
} from 'vant'
Vue.use(Sticky)

// 29.侧边导航
import {
  Sidebar,
  SidebarItem
} from 'vant'
Vue.use(Sidebar)
Vue.use(SidebarItem)

// 30.商品导航
import {
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton
} from 'vant'
Vue.use(GoodsAction).use(GoodsActionIcon).use(GoodsActionButton)

// 遮罩
import {
  Overlay
} from 'vant'
Vue.use(Overlay)

// 面板
import {
  Panel
} from 'vant'
Vue.use(Panel)

// search
import {
  Search
} from 'vant'
Vue.use(Search)

// 刷新(下 拉)
import {
  PullRefresh
} from 'vant'
Vue.use(PullRefresh)

// 刷新(上拉)
import {
  List
} from 'vant'
Vue.use(List)

// 下拉面板
import {
  Collapse, CollapseItem
} from 'vant'
Vue.use(Collapse)
Vue.use(CollapseItem)
// 表单
import { Form } from 'vant'
Vue.use(Form)

// 下拉选择器
import { Picker } from 'vant'
Vue.use(Picker)

// 日期
import { Calendar } from 'vant'
Vue.use(Calendar)
// dialog

import { Dialog } from 'vant'
Vue.use(Dialog)
