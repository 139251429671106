import request from '@/utils/request'

// 查询系统消息列表
export function pageNoticeList(pageCurrent, pageSize, title, schoolCode, dataSource, publishFlag) {
  return request({
    url: 'common/com/notice/pageList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: {
      'pageCurrent': pageCurrent,
      'pageSize': pageSize,
      'title': title,
      'schoolCode': schoolCode,
      'dataSource': dataSource,
      'publishFlag': publishFlag
    }
  })
}

// 创建系统消息
export function createComNotice(data) {
  return request({
    url: 'common/com/notice',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

// 修改系统消息方法
export function updateComNotice(data) {
  return request({
    url: 'common/com/notice',
    method: 'put',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

// 删除系统信息
export function deleteComNotice(ids) {
  return request({
    url: 'common/com/notice/del',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      'ids': ids
    }
  })
}

// 发布系统信息
export function withdrawnComNotice(ids) {
  return request({
    url: 'common/com/notice/withdrawnNotice',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      'ids': ids
    }
  })
}

// 撤销发布系统信息
export function publishComNotice(ids) {
  return request({
    url: 'common/com/notice/publishNotice',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      'ids': ids
    }
  })
}

// 首页查询消息列表
export function noticeList() {
  return request({
    url: 'common/com/notice/noticeList',
    method: 'get'
  })
}

export function getStuNotice(schoolCode, dataSource) {
  return request({
    url: 'common/com/notice/getStuNotice',
    method: 'POST',
    data: {
      'schoolCode': schoolCode,
      'dataSource': dataSource
    }
  })
}
