<!-- 首页 -->
<template>
  <div id="dashboard" ref="dashboard" style="background-color: #ffffff">
    <div>
      <div :style="{'background-image':'url('+imgTop+')'}" style="height:150px;background-size:100% 100%">
      </div>
      <!-- 轮播图 -->
      <!-- <div class="swipe">
        <van-swipe
          :autoplay="3000"
          indicator-color="white"
        >
          <van-swipe-item v-for="(image, index) in swipeList" :key="index">
            <img :src="image" class="img swipe-img">
          </van-swipe-item>
          <template #indicator>
            <div />
          </template>
        </van-swipe>
      </div> -->
      <project-info />
      <!-- 消息中心 -->
      <div class="message-center">
        <p class="title-text">{{ $t('home.news') }}</p>
        <ul class="message-box">
          <li class="message-item clearfix" @click="mesClick">
            <div class="left">
              <img :src="messageImg" alt="" class="img message-img">
            </div>
            <div v-if="stuNotice.title" class="left middle">
              <div class="text-first wrapText" v-html="stuNotice.title" />
              <p class="text-second message-date">{{ stuNotice.updateDate }}</p>
            </div>
            <div v-if="!stuNotice.title" class="left middle">
              <div class="text-first wrapText">欢迎使用</div>
              <p class="text-second message-date">易缴费平台</p>
            </div>
            <div class="arrowImg-box">
              <img :src="arrowImg" alt="" class="img arrowImg">
            </div>
          </li>
        </ul>
      </div>
      <van-popup v-model="mesDialogShow" class="mesPopup" position="top">
        <div class="message-box">
          <span class="notice">{{ $t('home.news') }}</span>
          <div class="html-p content" v-html="stuNotice.content" />
          <p class="html-p date">{{ stuNotice.updateDate }}</p>
        </div>
      </van-popup>
      <!-- 底部菜单栏 开始 -->
      <ReiTabbar />
    <!-- 底部菜单栏 结束 -->
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { getRecommendedProjectList, getProjectIsAllowPayment } from '@/api/project'
import { getStuNotice } from '@/api/notice'
import Vue from 'vue'
import ReiTabbar from './components/ReiTabbar.vue'
import preventBack from 'vue-prevent-browser-back' // 组件内单独引入
import ProjectInfo from './components/ProjectInfo.vue'
import {
  Swipe,
  SwipeItem,
  Button,
  Image,
  Grid,
  GridItem,
  Card,
  Divider,
  Row,
  Col
} from 'vant'

Vue.use(Grid).use(GridItem)
Vue.use(Button)
Vue.use(Image)
Vue.use(Swipe).use(SwipeItem)
Vue.use(Card)
Vue.use(Divider)
Vue.use(Row).use(Col)

export default {
  name: 'Index',
  components: {
    ReiTabbar, ProjectInfo
  },
  mixins: [preventBack], // 注入
  data() {
    return {
      needHide: '', // 隐藏p字段,为true显示,为false隐藏
      // 消息中心弹出
      mesDialogShow: false,
      mesObj: {},
      // top图
      imgTop: require('../assets/newImages/banner_index.jpg'),
      // message
      messageImg: require('../assets/new_icon/news.png'),
      // 箭头
      arrowImg: require('../assets/newImages/home_arrow.png'),
      // 8个功能图标数组
      recommendedProjectList: [],
      iconList: [{
        imgUrl: require('../assets/newImages/icon8.png'),
        name: '更多',
        path: 'moreMpplications'
      }],
      // 特色服务图
      featureIconList: [
        require('../assets/new_icon/ticket.png'),
        require('../assets/new_icon/refund.png')
      ],
      // 轮播图
      swipeList: [
        require('../assets/newImages/swipe.png')
      ],
      // 消息中心
      stuNotice: {},
      topNum: '', // 顶部项目列数
      margin_part: {} // 特色服务顶部margin属性
    }
  },
  beforeMount() {
    document.querySelector('body').setAttribute('style', 'background: #FFFFFF')
    this.getRecommendedProjectList()
  },
  mounted() {
    if (window.localStorage.getItem('schoolcode') === '10335JX000001') {
      this.swipeList[0] = require('../assets/newImages/swipe' + window.localStorage.getItem('schoolcode') + '.png')
    }
    window.addEventListener('popstate', this.listener)
    this.getStuNotice()
    window.addEventListener('scroll', this.scrollToTop)
    this.top()
    this.hidPart()
    // 监听resize方法
    // window.addEventListener("resize", this.renderResize, false);
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollToTop)
    window.removeEventListener('popstate', this.listener)
  },
  methods: {
    listener() {
      if (window.localStorage.getItem('schoolcode') === '10335JX000001' || window.localStorage.getItem('schoolcode') === '1000922' || window.localStorage.getItem('schoolcode') === '0760002' || window.localStorage.getItem('schoolcode') === '0562001') {
        window.location = 'https://yth-k12.newcapec.cn/app/'
      } else if (window.localStorage.getItem('schoolcode') === '0713025') {
        window.location = 'https://mhpt-k12.newcapec.cn/dist/index.html?token=' + window.localStorage.getItem('k12token')
      }
    },
    hidPart() {
      if (window.localStorage.getItem('language') === 'cn') {
        this.needHide = true
      } else {
        this.needHide = false
      }
    },
    searchClick() {
      this.$router.push({ path: 'searchPay' })
    },
    mesClick(item) {
      this.mesDialogShow = true
    },
    // 跳转项目缴费页面
    goToPay(item) {
      getProjectIsAllowPayment(item.id).then(response => {
        this.messageCode = response.messageCode
        if (this.messageCode !== '0') {
          Toast.fail({
            message: response.data,
            type: 'error'
          })
          return false
        } else {
          // 20210722 add by lihongyan 如果是电费electric，路由地址加上factoryCode厂商代码
          if (item.proModelUrl === 'electric') {
            this.$router.push({ name: item.proModelUrl + 'Pay' + item.factoryCode, query: { projectId: item.id }})
          } else if (item.proModelUrl === 'standard') {
            this.$router.push({ name: 'commonStandardPay', query: { projectId: item.id }})
          } else if (item.proModelUrl === 'mealsFee') {
            this.$router.push({ name: item.proModelUrl + 'Pay' + item.factoryCode, query: { projectId: item.id }})
          } else {
            // 根据不同Mode要进不同页面走不同接口
            this.$router.push({ path: item.proModelUrl + 'Pay', query: { projectId: item.id }})
          }
        }
      }).catch(err => {
        Toast.fail(err.message)
      })
    },
    // 去更多
    toMore() {
      this.$router.push({ path: 'moreMpplications' })
    },
    // 查询推荐服务 7图标+更多
    getRecommendedProjectList() {
      getRecommendedProjectList(window.localStorage.getItem('schoolcode')).then(response => {
        // console.log('all')
        // console.log(response.data)
        this.recommendedProjectList = response.data
        this.topNum = Math.ceil((this.recommendedProjectList.length + 1) / 4)
        this.margin_part = { 'margin-top': 'calc(' + Math.ceil((this.recommendedProjectList.length + 1) / 4) + '*75px)' }
      }).catch(err => {
        Toast.fail(err.message)
      })
    },
    // 消息中心
    getStuNotice() {
      getStuNotice(window.localStorage.getItem('schoolcode'), this.$store.state.settings.dataSource).then(response => {
        if (response.data) {
          this.stuNotice = response.data
        }
      }).catch(err => {
        Toast.fail(err.message)
      })
    },
    doMethod(vueName) {
      this.$router.push({ path: vueName })
    },
    // 初始回顶部
    top() {
      // var scrolltop = document.documentElement.scrollTop || document.body.scrollTop
      document.documentElement.scrollTop = document.body.scrollTop = 0
      //  scroll('0px', 300);
    }
  }

}
</script>
<style lang="scss" scoped>
    .notice {
        font-size: 20px;
        font-weight: bold;
        line-height: 40px;
    }

    .mesPopup {
        border-radius: 0 0 20px 20px;
        padding: 20px;

        .content {
            line-height: 30px;
        }

        .date {
            text-align: right;
            color: #CCCCCC;
            font-size: 16px;
        }
    }

    .html-p {
        font-size: 18px;
    }

    .wrapText {
        line-height: 30px;
        font-weight: normal !important;
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        color: #2E2E2E;
        height: 14px;
        font-size: 14px;
    }
      .wrapText1 {
        line-height: 40px;
        font-weight: normal !important;
        width: 100%;
        overflow: visible;
        text-overflow: visible;
        //display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        color: #2E2E2E;
        height: 8px;
        font-size: 8px;
    }
    .dashbord {
        position: relative;
    }

    .my-swipe .van-swipe-item {
        color: #fff;
        font-size: 20px;
        line-height: 150px;
        text-align: center;
        background-color: #39a9ed;
    }

    // #login{
    //   // border-top:1px solid rgba(55,147,229,1);
    //   // background:linear-gradient(135deg,rgba(55,147,229,1) 0%,rgba(61,105,199,1) 100%);
    // }
    .top {
        padding: 0 14px;
        // background: linear-gradient(135deg, rgba(55, 147, 229, 1) 0%, rgba(61, 105, 199, 1) 100%);
        height: 140px;
        padding-top: 30px;
        position: relative;
        background-color: #FFFFFF;

        .top-img-box {
            height: 33px;
            position: relative;

            .imgTop {
                // width: 97px;
                height: 33px;
                // background: white;
                // color: white;
            }

            .title {
                height: 33px;
                margin-left: 24px;
                line-height: 33px;
                font-size: 14px;
                font-weight: lighter;
                color: rgba(255, 255, 255, 1);
            }

            .language {
                height: 33px;;
                line-height: 33px;
                font-size: 12px;
                font-weight: 500;
                color: rgba(255, 255, 255, 0.7);
            }

            .line {
                width: 1px;
                height: 17px;
                background: white;
                position: absolute;
                top: 50%;
                left: 32%;
                transform: translate(-50%, -50%);
            }
        }

        .second {
            margin-top: 10px;
            border-radius: 8px;
            background: #fff;
            padding: 10px 10px 30px;
            box-shadow: 0px 0px 10px 0px rgba(47, 159, 242, 0.15);
        }

        .imgBox {
            width: 25%;
            padding: 4px;

            img {
                margin: 7px auto;
                width: 46px;
                height: 46px;
                border: 1px solid rgba(47,159,242,.3);
                border-radius: 6px;
            }

            .text {
                text-align: center;
                // width:60px;
                margin: 0 auto;
                height: 12px;
                font-size: 12px;
                font-weight: 300;
                color: rgba(46, 46, 46, 1);
                line-height: 12px;
            }
              .textname {
                text-align: center;
                // width:60px;
                margin: 0 auto;
                height: 12px;
                font-size: 8px;
                font-weight: 550;
                color: rgba(46, 46, 46, 1);
                line-height: 12px;
            }
        }
        .more{
          img{
            border: 0;
          }
        }
    }

    .title-text {
        // width:56px;
        line-height: 40px;
        height: 40px;
        font-size: 14px;
        font-weight: 500;
        color: #2e2e2e;
        line-height: 40px;
    }
    .third {
        // margin-top: calc(2*75px);
        padding: 0 14px;

        .item-feature {
            box-shadow: 0px 0px 10px 0px rgba(47, 159, 242, 0.15);
            border-radius: 6px;
            padding: 13px 11px;

            .feature-img {
                width: 34px;
                height: 34px;
                border-radius: 6px;
            }
            .flex-c{
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .feature-text {
                margin-left: 10px;
                height: 34px;

                .text-first {
                    // width:56px;
                    height: 17px;
                    font-size: 14px;
                    color: #2E2E2E;
                    line-height: 17px;
                }

                .text-second {
                    // margin-top:4px;
                    height: 17px;
                    font-size: 12px;
                    color: #747474;
                    line-height: 17px
                }
            }
        }
    }

    .message-date {
        // margin-top: 4px;
        font-weight: normal !important;
    }

    .swipe {
        border-top: 10px solid #f5f5f5;
        border-bottom: 10px solid #f5f5f5;
        padding: 10px 10x  1px 14px;
        width: 100%;
        // border-radius: 8px;
        overflow: hidden;

        .swipe-img {
            width: 100%;
            border-radius: 8px;
        }
    }

    .message-center {
        padding: 0 14px;
        padding-bottom: 70px;
        margin-top: 10px;

        .title-text {
            height: 40px;
            line-height: 40px;
        }

        .message-box {
            .message-item {
                background: #fff;
                padding: 13px 11px;
                border-radius: 6px;
                box-shadow: 0px 0px 10px 0px rgba(47, 159, 242, 0.15);
                position: relative;

                .message-img {
                    width: 34px;
                    height: 34px;
                    border-radius: 6px;
                    background-color: rgb(249 102 98);
                }

                .middle {
                    margin-left: 14px;
                    height: 34px;

                    .text-first {
                        width: 210px;
                        height: 17px;
                        font-size: 14px;
                        font-weight: 300;
                        color: #2E2E2E;
                        line-height: 17px;
                    }

                    .text-second {
                        height: 17px;
                        font-size: 12px;
                        font-weight: 300;
                        color: #747474;
                        line-height: 17px;
                    }
                }

                .arrowImg-box {
                    position: absolute;
                    top: 50%;
                    right: 10px;
                    transform: translate(-50%, -50%);

                    .arrowImg {
                        width: 11px;
                        height: 20px;
                    }
                }
            }
        }
    }
</style>
