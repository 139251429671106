// import vant from './plugins/vant';
// import httpUtil from '@/axios/index.js';

export default {

  /**
     * 消息提示弹出窗（success/error/warning）
     * @method 消息提示框
     * @for 样式
     * @param {type} type类型
     * @param {content} content内容
     * @return {Function} 显示弹框
     */
  // message: (type, content) => {
  //     element.Message({
  //         message: content,
  //         type: type
  //     });
  // },

  /**
     * 成功提示弹出窗
     * @method success消息提示框
     * @for 样式
     * @param {content} content内容
     * @return {Function} 显示弹框
     */
  // success: (content) => {
  //     element.Message({
  //         message: content,
  //         type: 'success'
  //     });
  // },

  /**
     * 错误提示弹出窗
     * @method success消息提示框
     * @for 样式
     * @param {content} content内容
     * @return {Function} 显示弹框
     */
  // error: (content) => {
  //     element.Message({
  //         message: content,
  //         type: 'error'
  //     });
  // },

  /**
     * 是否操作提示窗口
     * @method success消息提示框
     * @for 样式
     * @param {content} 提示内容
     * @param {retFun} 返回方法
     * @return {Function} 显示弹框
     */
  // confirm: (content, retFun) => {
  //     element.MessageBox.confirm(content, '提示', {
  //         confirmButtonText: '确定',
  //         cancelButtonText: '取消',
  //         type: 'warning'
  //     }).then(() => {
  //         //成功执行
  //         retFun()
  //     }).catch(() => {
  //         //取消方法
  //     });
  // },

  /**
     * 警告提示弹出窗
     * @method warning消息提示框
     * @for 样式
     * @param {content} content内容
     * @return {Function} 显示弹框
     */
  // warning: (content) => {
  //     element.Message({
  //         message: content,
  //         type: 'warning'
  //     });
  // },
  /**
     * 通知窗
     * @method 通知窗提示
     * @for 样式
     * @param {content} content内容
     * @return {Function} 显示通知框
     */
  // notify: (content) => {
  //   element.Notification({
  //         title: '通知',
  //         message: content,
  //         dangerouslyUseHTMLString: true,
  //         position: 'bottom-right',
  //         duration: 0
  //       });

  // },

  /**
     * 拷贝对象
     * @method 工具方法
     * @for 工具
     * @param {o} 对象
     * @return {Function} 拷贝对象
     */
  copyObj: (o) => {
    var obj = {}
    obj = JSON.parse(JSON.stringify(o))
    return obj
  },

  // 中文校验
  check: (data) => {
    if (data === '中文') {
      return { pattern: /^[\u4E00-\u9FA5]+$/, message: '公司名称只能是中文' }
    }
  },

  /**
     * 判断是否为空
     * @method 工具方法
     * @for 工具
     * @param s 字符串
     * @return {Boolean }结果
     */
  isEmpty: (s) => {
    if (s === undefined || s === null || s === '') {
      return true
    } else { return false }
  },
  /**
     * 获取当前时间
     */
  // thisDate:()=>{
  //     var now = new Date();
  //     var year = now.getFullYear(); //得到年份
  //     var month = now.getMonth(); //得到月份
  //     var date = now.getDate(); //得到日期
  //     var day = now.getDay(); //得到周几
  //     month = month + 1;
  //     if (month < 10) month = "0" + month;
  //     if (date < 10) date = "0" + date;
  // return year + "-" + month + "-" + date;
  // },

  /**
     * 格式化时间显示方式
     * @param v 时间
     * @param format "yyyy-MM-dd hh:mm:ss"
     */
  formatDate: (v, format) => {
    if (!v) return ''
    if (typeof v === 'number') {
      v = new Date(v)
    }
    var d = v
    if (typeof v === 'string') {
      if (v.indexOf('/Date(') > -1) { d = new Date(parseInt(v.replace('/Date(', '').replace(')/', ''), 10)) } else { d = new Date(Date.parse(v.replace(/-/g, '/').replace('T', ' ').split('.')[0])) } // .split(".")[0] 用来处理出现毫秒的情况，截取掉.xxx，否则会出错
    }
    var o = {
      'M+': d.getMonth() + 1, // month
      'd+': d.getDate(), // day
      'h+': d.getHours(), // hour
      'm+': d.getMinutes(), // minute
      's+': d.getSeconds(), // second
      'q+': Math.floor((d.getMonth() + 3) / 3), // quarter
      'S': d.getMilliseconds() // millisecond
    }
    if (/(y+)/.test(format)) {
      format = format.replace(RegExp.$1, (d.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (var k in o) {
      if (new RegExp('(' + k + ')').test(format)) {
        format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
      }
    }
    return format
  },

  dateFormat: function(time) {
    var date = new Date(time)
    var year = date.getFullYear()
    /* 在日期格式中，月份是从0开始的，因此要加0
		 * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
		 * */
    var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    // 拼接
    return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
  },

  /**
     * 货币格式转换（人民币）
     * @param s 金额
     */
  // parsePrice:(s)=>{
  //    s=Number(s)
  //    let n=1;//保留一位小数
  //     n = n > 0 && n <= 20 ? n : 2;
  //     s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
  //     let l = s.split(".")[0].split("").reverse(),
  //     r = s.split(".")[1];
  //     let t = "";
  //     for (let i = 0; i < l.length; i++) {
  //         t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
  //     }
  //     return  t.split("").reverse().join("") + "." + r;
  //   },
  /**
 * 货币格式转换（人民币）
 * @param s 金额
 */
  changeBigmoney: (n) => {
    if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(n)) {
      return ''
    }
    var unit = '仟佰拾亿仟佰拾万仟佰拾圆角分'
    var str = ''
    n += '00'
    var p = n.indexOf('.')
    if (p >= 0) {
      n = n.substring(0, p) + n.substr(p + 1, 2)
    }
    unit = unit.substr(unit.length - n.length)
    for (var i = 0; i < n.length; i++) {
      str += '零壹贰叁肆伍陆柒捌玖'.charAt(n.charAt(i)) + unit.charAt(i)
    }
    return str.replace(/零(仟|佰|拾|角)/g, '零').replace(/(零)+/g, '零').replace(/零(万|亿|圆)/g, '$1').replace(/(亿)万|壹(拾)/g, '$1$2').replace(/^圆零?|零分/g, '').replace(/圆$/g, '圆整')
  }
}
export const format = {
  /**
     * 一维数组转换成树形结构
     * @param source 一维数组
     * @param codeKey code元素key
     * @param parentCodeKey parentCode元素key
     * @param rootParentVal 顶级元素的上级编码
     */
  formatTree: (source, codeKey, parentCodeKey, rootParentVal) => {
    const result = []
    source.forEach(s => {
      if (s[parentCodeKey] === rootParentVal) {
        result.push(s)
        const child = format.formatTree(source, codeKey, parentCodeKey, s[codeKey])
        if (child.length > 0) {
          s['children'] = child
        }
      }
    })
    return result
  }
}
export function fenToYuan(num) {
  num = num * 0.01
  num += ''
  var reg = num.indexOf('.') > -1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g
  num = num.replace(reg, '$1')
  num = toDecimal2(num)
  return num
}
export function toDecimal2(x) {
  var f = parseFloat(x)
  if (isNaN(f)) {
    return false
  }
  f = Math.round(x * 100) / 100
  var s = f.toString()
  var rs = s.indexOf('.')
  if (rs < 0) {
    rs = s.length
    s += '.'
  }
  while (s.length <= rs + 2) {
    s += '0'
  }
  return s
}
