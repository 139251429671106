const getters = {
  sidebar: state => state.app.sidebar,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  device: state => state.app.device,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  idserial: state => state.user.idserial,
  roles: state => state.user.roles,
  userId: state => state.user.userId,
  userTel: state => state.user.tel,
  userEmail: state => state.user.email,
  permission_routes: state => state.permission.routers,

  loadMenus: state => state.user.loadMenus,
  addRouters: state => state.permission.addRouters,
  sqlApi: state => state.api.sqlApi
}
export default getters
