import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../pages/index'
import login from '../pages/login/index'
import { getToken } from '@/utils/auth'
import { Toast } from 'vant'
Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: {
      path: '/'
    }
  },
  {
    path: '/',
    name: 'toLogin',
    component: login,
    meta: { index: 0 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/quicklylogin',
    name: 'quicklylogin',
    component: () => import('../pages/login/quicklylogin/index.vue'),
    meta: { index: 0 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/loginBinding',
    name: 'loginBinding',
    component: () => import('../pages/loginBinding/index.vue'),
    meta: { index: 0 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/goldPlan',
    name: 'goldPlan',
    component: () => import('../pages/goldPlan/index.vue'),
    meta: { index: 0 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/signUp',
    name: 'signUp',
    component: () => import('../pages/signUp/index.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('../pages/login/account.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/paySuccess',
    name: 'paySuccess',
    component: () => import('../pages/paySuccess/index.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/payError',
    name: 'payError',
    component: () => import('../pages/paySuccess/error.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/signUpLogin',
    name: 'signUpLogin',
    component: () => import('../pages/signUpLogin/index.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/signRegister',
    name: 'signRegister',
    component: () => import('../pages/signRegister/index.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/paySuccess',
    name: 'paySuccess',
    component: () => import('../pages/paySuccess/index.vue'),
    meta: { index: 2 }
  },
  {
    path: '/signDetail',
    name: 'signDetail',
    component: () => import('../pages/signDetail/index.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../pages/register/index.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/home',
    name: 'home',
    component: Index,
    meta: { index: 1 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/orderList',
    name: 'order',
    component: () => import('../pages/orderlist/index.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/personCenter',
    name: 'personCenter',
    component: () => import('../pages/personCenter/index.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/moreMpplications',
    name: 'moreMpplications',
    component: () => import('../pages/moreMpplications/index.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: () => import('../pages/orderDetail/index.vue'),
    meta: { index: 3 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/more',
    name: 'more',
    component: () => import('../pages/more/index.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/commonPay',
    name: 'commonPay',
    component: () => import('../pages/commonPay/index.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/nameImportPay',
    name: 'nameImportPay',
    component: () => import('../pages/nameImportPay/index.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/studentFeesPay',
    name: 'studentFeesPay',
    component: () => import('../pages/studentFeesPay/index.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/nameStandardImportPay',
    name: 'nameStandardImportPay',
    component: () => import('../pages/nameStandardImportPay/index.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/sanxinIcbcNameListPay',
    name: 'sanxinIcbcNameListPay',
    component: () => import('../pages/sanxinIcbcNameListPay/index.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/meetingPay',
    name: 'meetingPay',
    component: () => import('../pages/meetingPay/index.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/payQrcode',
    name: 'payQrcode',
    component: () => import('../pages/payQrcode/index.vue'),
    meta: { index: 3 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  // {
  //   path: '/todo',
  //   name: 'todo',
  //   component: () => import('../pages/todo/index.vue'),
  //   meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  // },
  {
    path: '/progressTracking',
    name: 'progressTracking',
    component: () => import('../pages/progressTracking/index.vue'),
    meta: { index: 3 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/tuitionAndDormPay',
    name: 'tuitionAndDorm',
    component: () => import('../pages/tuitionAndDormPay/index.vue'),
    meta: { index: 3 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/balanceInquiry',
    name: 'balanceInquiry',
    component: () => import('../pages/balanceInquiry/index.vue'),
    meta: { index: 3 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/searchPay',
    name: 'searchPay',
    component: () => import('../pages/searchPay/index.vue'),
    meta: { index: 3 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/leaveSchoolPayWay',
    name: 'leaveSchoolPayWay',
    component: () => import('../pages/leaveSchoolPayWay/index.vue'),
    meta: { index: 3 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/searchPayDetail',
    name: 'searchPayDetail',
    component: () => import('../pages/searchPayDetail/index.vue'),
    meta: { index: 3 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/InformList',
    name: 'InformList',
    component: () => import('../pages/inform/InformList.vue'),
    meta: { index: 3 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/person',
    name: 'person',
    component: () => import('../pages/person/index.vue'),
    meta: { index: 3 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/leaveSchoolToPay',
    name: 'leaveSchoolToPay',
    component: () => import('../pages/leaveSchoolToPay/index.vue'),
    meta: { index: 4 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/reimbursementDetails',
    name: 'reimbursementDetails',
    component: () => import('../pages/reimbursementDetails/index.vue'),
    meta: { index: 4, keepAlive: true } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/signatures',
    name: 'signatures',
    component: () => import('../pages/signatures/index.vue'),
    meta: { index: 5 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
    // meta: {
    // 	keepAlive: true
    // }
  },
  {
    path: '/password',
    name: 'password',
    component: () => import('../pages/password/index.vue'),
    meta: { index: 4 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/accountBalance',
    name: 'accountBalance',
    component: () => import('../pages/accountBalance/index.vue'),
    meta: { index: 4 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/unbundlingCard',
    name: 'unbundlingCard',
    component: () => import('../pages/unbundlingCard/index.vue'),
    meta: { index: 4 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/refundApplication',
    name: 'refundApplication',
    component: () => import('../pages/refundApplication/index.vue'),
    meta: { index: 4 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/refundApplicationEdit',
    name: 'refundApplicationEdit',
    component: () => import('../pages/refundApplicationEdit/index.vue'),
    meta: { index: 5 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/InformDetail',
    name: 'InformDetail',
    component: () => import('../pages/inform/InformDetail.vue'),
    meta: { index: 4 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/transferError',
    name: 'transferError',
    component: () => import('../pages/welcomeNewStu/transferError.vue'),
    meta: { index: 4 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/eCardRechargePay',
    name: 'eCardRechargePay',
    component: () => import('../pages/charge/chargeCard.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/chargeElectricity',
    name: 'chargeElectricity',
    component: () => import('../pages/charge/chargeElectricity.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/chargeElectricity2',
    name: 'chargeElectricity',
    component: () => import('../pages/charge/chargeElectricity2.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/openapituitionAndDorm',
    name: 'openapituitionAndDorm',
    component: () => import('../pages/welcomeNewStu/transfer.vue'),
    meta: { index: 4 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/openapituitionAndDorm_detail',
    name: 'openapituitionAndDorm_detail',
    component: () => import('../pages/welcomeNewStu/tuitionAndDorm.vue'),
    meta: { index: 4 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/openorderlist',
    name: 'openorderlist',
    component: () => import('../pages/welcomeNewStu/orderlist.vue'),
    meta: { index: 4 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/middle_page',
    name: 'middle_page',
    component: () => import('../pages/welcomeNewStu/middle_page.vue'),
    meta: { index: 4 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/qrcodeCommonPay',
    name: 'qrcodeCommonPay',
    component: () => import('../pages/qrcodeCommonPay/index.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/invoiceTitleList',
    name: 'invoiceTitleList',
    component: () => import('../pages/invoiceTitle/index.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/invoiceTitleDetail',
    name: 'invoiceTitleDetail',
    component: () => import('../pages/invoiceTitle/detail.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/chargeElectricityE016',
    name: 'electricPayE016',
    component: () => import('../pages/charge/chargeElectricityE016.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/chargeElectricityE017',
    name: 'electricPayE017',
    component: () => import('../pages/charge/chargeElectricityE017.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/chargeElectricityE034',
    name: 'electricPayE034',
    component: () => import('../pages/charge/chargeElectricityE034.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/chargeElectricityE050',
    name: 'electricPayE050',
    component: () => import('../pages/charge/chargeElectricityE050.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/chargeElectricityE011',
    name: 'electricPayE011',
    component: () => import('../pages/charge/chargeElectricityE011.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/chargeMealsFeeMF0001',
    name: 'mealsFeePayMF0001',
    component: () => import('../pages/mealsFee/mealsFeePayMF0001.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  // 云平台迎新免登陆页面
  {
    path: '/qrcodeYingXin',
    name: 'qrcodeYingXin',
    component: () => import('../pages/yingXin/qrcode/index.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  // 云平台迎新收费页面
  {
    path: '/standardPay',
    name: 'standardPay',
    component: () => import('../pages/yingXin/standardPay/index.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  {
    path: '/commonStandardPay',
    name: 'commonStandardPay',
    component: () => import('../pages/standardPay/index.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  // 云平台报名缴费注册页面
  {
    path: '/payForRegistration',
    name: 'payForRegistration',
    component: () => import('../pages/payForRegistration/login/index.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  },
  // 云平台报名缴费代缴费页面
  {
    path: '/paymentOnBehalf',
    name: 'paymentOnBehalf',
    component: () => import('../pages/payForRegistration/paymentOnBehalf/index.vue'),
    meta: { index: 2 } // meta对象的index用来定义当前路由的层级,由小到大,由低到高
  }
]

const router = new VueRouter({
  routes
})

export default router

const whiteList = ['/quicklylogin', '/', '/loginBinding', '/signUp', '/signUpLogin', '/signRegister', '/register', '/transferError', '/openapituitionAndDorm', '/openorderlist', '/middle_page', '/qrcodeCommonPay', '/goldPlan', '/qrcodeYingXin', '/standardPay', '/login', '/payForRegistration']// 登陆白名单

router.beforeEach((to, from, next) => {
  // 判断是否存在token
  if (getToken()) {
    // 已登录且要跳转的页面是登录页
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      next()
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
      next()
    } else {
      Toast.fail('登录超时，请重新登录')
      next(`/${to.path}`)
    }
  }
})
