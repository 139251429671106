const baseUrl = process.env.VUE_APP_BASE_API
const sqlUtl = 'http://localhost:8090/druid'
// const reiAttrUrl = 'http://localhost:8090/rei/attrFile/'
const reiAttrUrl = 'rei/attrFile/'
const api = {
  state: {
    // Sql 监控
    sqlApi: sqlUtl,
    // 修改头像
    updateAvatarApi: baseUrl + '/system/user/userHeadImgUpload',
    // baseUrl，
    baseApi: baseUrl,
    fileUploadApi: baseUrl + 'localStorage/attrUpload',
    reiAttrUrl: baseUrl + reiAttrUrl
  }
}
export default api
