// import variables from '@/styles/element-variables.scss'
import defaultSettings from '@/settings'

const { showSettings, tagsView, fixedHeader, sidebarLogo, size, showFooter, footerTxt, caseNumber, uniqueOpened, dataSource, schoolCode, partnerId } = defaultSettings

const state = {
  showRightPanel: false,
  settingBtn: false,
  //   theme: variables.theme,
  showSettings: showSettings,
  tagsView: tagsView,
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo,
  showFooter: showFooter,
  uniqueOpened: uniqueOpened,
  footerTxt: footerTxt,
  caseNumber: caseNumber,
  size: size,
  schoolCode: schoolCode,
  dataSource: dataSource,
  partnerId: partnerId
}

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    if (state.hasOwnProperty(key)) {
      state[key] = value
    }
  }
}

const actions = {
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data)
  }
}

export default {
  state,
  mutations,
  actions
}

