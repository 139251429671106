import axios from 'axios'
import { Dialog } from 'vant'
// import { MessageBox, Message } from 'element-ui'
import { Toast } from 'vant'
import store from '@/store'
import { getToken } from '@/utils/auth'
import router from '@/router/routers'

// 储存cancel token
let axiosPromiseArr = []
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['X-Token'] = getToken()
    }
    // 在发送请求设置cancel token
    config.cancelToken = new axios.CancelToken(cancel => {
      axiosPromiseArr.push({ cancel })
    })
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.messageCode !== '0') {
      // 建议更新token
      if (res.messageCode === '-3') {
        // 更新token后继续执行之前的请求
        return doRequest(response)
      } else if (res.messageCode === '-4') {
        // 如果返回该账号已经在某一设备上登录，需要提示此账号当前登录已经被登出及重新跳转的操作
        return toRedirectUrl(res)
      } else if (res.messageCode === '-1' || res.messageCode === '-2' || res.code === 50014) {
        // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
        const cancelReq = new Promise((resolve) => {
          axiosPromiseArr.forEach((ele, index) => {
            ele.cancel()
            delete axiosPromiseArr[index]
          })
          resolve(res)
        })
        cancelReq.then(() => {
          store.dispatch('user/resetToken').then(() => {
            Dialog.confirm({
              message: '你已被登出，可以取消继续留在该页面，或者重新登录,确定登出',
              confirmButtonText: '重新登录',
              cancelButtonText: '取消',
              type: 'warning',
              callback: action => {
                if (action === 'cancel' || action === 'confirm') {
                  // 跳转回login路由，并把目标路由的url路径保存在login的query中
                  router.replace({
                    name: 'toLogin',
                    query: { redirect: router.history.current.fullPath }
                  })
                }
              }
            })
          })
        })
      } else if (res.messageCode === '16510') {
        return res.messageCode
      } else {
        Toast.fail({
          message: res.message || 'Error',
          type: 'error',
          duration: 5 * 1000
        })
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      axiosPromiseArr = []
      return res
    }
  },
  error => {
    // 请求取消时，也会进入error，根据axios.isCancel()：true--请求取消  false--请求失败
    // 仅在请求失败时做后续处理
    if (!axios.isCancel(error)) {
      error.message = 'cancel request'
      return Promise.reject(error)
    }
    if (error && error.response) {
      switch (error.response.status) {
        case 404:
          router.push({ path: '404' })
          break
        case 500:
          var loginStr = 'login'
          var diff = error.request.responseURL.length - loginStr.length
          if (diff >= 0 && error.request.responseURL.lastIndexOf(loginStr) === diff) {
            error.message = `连接出错,状态码(${error.response.status})!`
            Toast.fail({
              message: error.message || 'Error',
              type: 'error',
              duration: 5 * 1000
            })
          } else {
            router.push({ path: '500' })
          }
          break
        default: error.message = `连接出错,状态码(${error.response.status})!`
          Toast.fail({
            message: error.message || 'Error',
            type: 'error',
            duration: 5 * 1000
          })
      }
    }
    return Promise.reject(error)
  }
)

/**
 * 提示账号已经被登出及添加登出后重新跳转的路径为当前请求
 **/
async function toRedirectUrl(res) {
  // 定义Promis，取消pending的请求，并返回错误
  const redirectPromise = new Promise((resolve, reject) => {
    axiosPromiseArr.forEach((ele, index) => {
      ele.cancel()
      delete axiosPromiseArr[index]
    })
    resolve(res)
    reject(new Error(res.message || 'Error'))
  }).catch(alert)
  // Promise回调后执行以下代码
  redirectPromise.then(res => {
    store.dispatch('user/autoLogout').then(() => {
      Dialog.alert(res.message, '提示', {
        confirmButtonText: '确定',
        type: 'warning',
        callback: action => {
          if (action === 'cancel' || action === 'confirm') {
            // 跳转回login路由，并把目标路由的url路径保存在login的query中
            router.replace({
              name: 'login',
              query: { redirect: router.history.current.fullPath }
            })
          }
        }
      })
    })
  })
  return Promise.reject(new Error(res.message || 'Error'))
}
// 由于更换token|更换后进行上次请求
async function doRequest(response) {
  store.dispatch('user/refreshToken', response.data.data)
  const config = response.config
  config.headers['X-Token'] = response.data.data
  if (config.data !== undefined) {
    config.data = JSON.parse(config.data)
  }
  const res = await axios.request(config)
  return res.data
}

export default service
