module.exports = {

  title: '缴费大厅',

  /**
     * @type {boolean} true | false
     * @description Whether show the settings right-panel
     */
  showSettings: true,

  /**
     * @type {boolean} true | false
     * @description Whether need tagsView
     */
  tagsView: false,

  /**
     * @type {boolean} true | false
     * @description Whether fix the header
     */
  fixedHeader: true,

  /**
     * @type {boolean} true | false
     * @description Whether show the logo in sidebar
     */
  sidebarLogo: true,
  /**
     * @type {boolean} true | false
     * @description 是否显示页脚版权信息
     */
  showFooter: true,
  /**
     * @type {String}
     * @description 首页底部显示版权信息
     */
  footerTxt: '',
  /**
     * @type {String}
     * @description 网站备案号
     */
  caseNumber: '',
  /**
     * @description 是否只保持一个子菜单的展开
     */
  uniqueOpened: true,
  /**
     * @description 密码超时时间默认1天
     */
  passCookieExpires: 1,
  /**
     * @description 记住密码状态下的token在Cookie中存储的天数，默认1天
     */
  tokenCookieExpires: 1,
  /**
     * @description 数据源
     */
  dataSource: 'PAY',
  /**
     * @description 学校代码
     */
  schoolCode: 'schoolcode0000001',
  /**
   * @description 合作者id
   */
  partnerId: 'PT000004'
}

