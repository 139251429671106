import request from '@/utils/request'

export function pageProjectList(pageCurrent, pageSize, projectCode, projectName, allowRebate, schoolCode) {
  return request({
    url: 'pay/project/pageList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: {
      'pageCurrent': pageCurrent,
      'pageSize': pageSize,
      'projectCode': projectCode,
      'projectName': projectName,
      'allowRebate': allowRebate,
      'schoolCode': schoolCode
    }
  })
}
export function getAllProjectList() {
  return request({
    url: 'pay/project/getAllProjectList',
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
// add by zhangyj 2020-05-19 查询所有推荐服务
export function getRecommendedProjectList(schoolCode) {
  return request({
    url: 'pay/project/getRecommendedProjectList',
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
    params: {
      'schoolCode': schoolCode
    }
  })
}
export function getProjectIsAllowPayment(id) {
  return request({
    url: 'pay/project/getProjectIsAllowPayment',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: {
      'projectId': id
    }
  })
}
export function yingXinSaveUser(data) {
  return request({
    url: 'pay/pay/yingXin/yingXinSaveUser',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: data
  })
}
export function saveUserGetStandNameList(idserial, schoolCode) {
  return request({
    url: 'pay/pay/yingXin/saveUserGetStandNameList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: {
      'idserial': idserial,
      'schoolCode': schoolCode
    }
  })
}
export function getTokenByIdeserialAndSchoolCode(schoolCode, idserial) {
  return request({
    url: 'pay/pay/yingXin/getTokenByIdeserialAndSchoolCode/' + schoolCode + '/' + idserial,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function closeOrderByIdserialAndProjectCode(idserial, schoolCode, projectCode) {
  return request({
    url: 'pay/pay/yingXin/closeOrderByIdserialAndProjectCode',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: {
      'idserial': idserial,
      'projectCode': projectCode,
      'schoolCode': schoolCode
    }
  })
}
// 保存报道学生及家长信息
export function reportSaveUserAndParent(data) {
  return request({
    url: 'pay/pay/reportPayment/reportSaveUserAndParent',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: data
  })
}
// 生成报道人员缴费信息
export function reportGeneratearent(idserial, schoolCode) {
  return request({
    url: 'pay/pay/reportPayment/reportGeneratearent',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: {
      'idserial': idserial,
      'schoolCode': schoolCode
    }
  })
}
// 查询报道人员缴费信息
export function reportQuery(idserial, schoolCode) {
  return request({
    url: 'pay/pay/reportPayment/reportQuery',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: {
      'idserial': idserial,
      'schoolCode': schoolCode
    }
  })
}
