<!--
 * @Author: 极客James
 * @Motto: 求知若渴,虚心若愚
 * @Github: https://github.com/Geek-James/ddBuy
 * @掘金: https://juejin.im/user/5c4ebc72e51d4511dc7306ce
 * @LastEditTime : 2020-01-04 15:32:21
 * @Description: 回到顶部组件
 * @FilePath: /ddBuy/src/components/backToTop/ToTop.vue
 -->
<template>
  <div
    v-show="showTop"
    class="scrollTop"
    @click="toTop"
  >
    <svg-icon
      icon-class="top"
      class="backtotop"
    />
  </div>
</template>
<script>
import SvgIcon from '../SvgIcon/index'
export default {
  name: 'ScrollTop',
  components: { SvgIcon },
  data() {
    return {
      scrollTop: 0,
      time: 0,
      dParams: 20,
      scrollState: 0
    }
  },
  computed: {
    showTop: function() {
      const value = this.scrollTop > 200
      return value
    }
  },
  mounted() {
    window.addEventListener('scroll', this.getScrollTop)
  },
  methods: {
    toTop(e) {
      this.scrollState = 1
      e.preventDefault()
      const distance = document.documentElement.scrollTop || document.body.scrollTop// eslint-disable-line no-unused-vars
      const _this = this
      this.time = setInterval(function() { _this.gotoTop(_this.scrollTop - _this.dParams) }, 10)
    },
    gotoTop(distance) {
      this.dParams += 20
      distance = distance > 0 ? distance : 0
      document.documentElement.scrollTop = document.body.scrollTop = window.pageYOffset = distance
      if (this.scrollTop < 10) {
        clearInterval(this.time)
        this.dParams = 20
        this.scrollState = 0
      }
    },
    getScrollTop() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    }
  }

}
</script>
<style scoped>
.backtotop {
  width: 50px;
  height:50px;
  color:#a1a1a1
}
.scrollTop {
  right: 0;
  position: fixed;
  bottom: 300px;
  cursor: pointer;
  z-index: 100;
}
</style>
