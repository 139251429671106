
import { Locale } from 'vant'
import zhCN from 'vant/lib/locale/lang/zh-CN'
import enUS from 'vant/lib/locale/lang/en-US'
var lang = localStorage.getItem('language') || 'cn'
changeLang(lang)

function changeLang(newlang) {
  if (newlang === 'en') {
    Locale.use('en', enUS)
  } else if (newlang === 'cn') {
    Locale.use('ch', zhCN)
  }
}

export function Local(mylang) {
  changeLang(mylang)
}
