// cn.js
const cn = {
  //  通用
  main: {
    'accountLogin': '账号密码登录',
    'return': '返回',
    'next': '下一步',
    'campus-pay-cost': '校园缴费平台',
    'more': '更多',
    'reset': '重置',
    'screen': '筛选',
    'no-more': '没有更多了',
    'home': '主页',
    'order': '订单',
    'mine': '我的',
    'selected': '已选',
    'inputMustWrite': '必填项不能为空',
    'save': '保存',
    'name': '姓名',
    'account': '账号',
    'delete': '删除',
    'tips': '提示',
    'confirm': '确定',
    'certificateNumber': '证件号',
    'money': '金额',
    'pay': '支付',
    'combinedPayment': '合并缴费'
  },
  // 提示
  msg: {
    'login': '请填写登录信息',
    'writeCode': '请填写验证码',
    'wrongCode': '验证码不正确',
    'noNull': '必填项不能为空',
    'loadSuccess': '加载成功',
    'loginSuccess': '登录成功',
    'pwdNull': '原/新密码不能为空',
    'pwdSame': '两次密码不一致',
    'pwdLength': '长度在 6 到 20 个字符',
    'pwdSize': '密码至少包括：数字、字符与字母',
    'amountOver': '当前未选择全额缴费，请输入小于本条未缴金额的数字！',
    'selectPaid': '请选择待缴费记录',
    'partPaid': '部分缴费不能为0或空',
    'selectItem': '请选择退费项目',
    'selectAccount': '请选择收款账户',
    'refundSuccess': '退费成功',
    'selectMethod': '请选择支付方式',
    'updateWrong': '更新订单异常',
    'saveSuccess': '保存成功',
    'chooseSchool': '请选择学校',
    'writePwd': '请填写密码',
    'truePhone': '请输入正确手机号',
    'keyEmpty': '关键字不能为空'
  },
  //  登录页
  login: {
    'title1': '欢迎使用',
    'title2': '校园缴费平台',
    'user-name': '用户名',
    'idserial': '请输入学号',
    'psw': '密码',
    'v-code': '验证码',
    'login': '登录',
    'register': '注册',
    'status': '前往校园统一身份认证',
    'fee': '报名缴费',
    'change': '换个登录方式？',
    'school': '学校',
    'quickly-pay': '快速缴费',
    'payQuery': '缴费查询'
  },
  //  注册页
  register: {
    'title': '注册',
    'school': '学校：',
    'choose-school': '请选择学校',
    'account': '账号：',
    'fill-account': '请填写账号',
    'psw': '密码：',
    'psw-input': '6-20位包含数字字母字符',
    'psw-confirm': '确认密码：',
    'psw-input2': '请再次填写密码',
    'tel': '电话号：',
    'tel-input': '请填写手机号'
  },
  //  首页
  home: {
    'characteristic': '特色服务',
    'search-pay': '查询缴费',
    'search-des': '房间场地 一键查询',
    'school-leave': '离校退费',
    'leave-title': '周期查账 缴费安心',
    'news': '通知'
  },
  //  个人中心
  person: {
    'title': '个人中心',
    'account': '账号：',
    'name': '姓名：',
    'id': '学工号：',
    'college': '学院：',
    'change-password': '修改密码',
    'unbundling': '解绑第三方账号',
    'queryAccountBalance': '查询银行卡余额',
    'toast-notifier': '通知提醒',
    'invoice-title': '发票抬头管理',
    'request-parcel': '去开发票',
    'user-guide': '使用指南',
    'refund-account': '退款账户管理',
    'fun-bill': '趣味账单',
    'log-out': '退出登录'
  },
  // 解绑
  unbundling: {
    'bottom-title': '已绑定第三方账号',
    'btn': '解绑'
  },
  //  订单中心
  order: {
    'title': '订单中心',
    'filter-query': '筛选查询',
    'start-data': '开始日期',
    'data-input': '请选择开始时间',
    'data-window': '选择时间',
    'end-data': '结束日期',
    'end-input': '请选择结束时间',
    'payment-items': '缴费项目',
    'payment-input': '请选择缴费项目',
    'order-status': '订单状态',
    'order-input': '请选择订单状态',
    'key-word': '关键字',
    'key-input': '请输入关键字'
  },
  //  待缴费
  pay: {
    // 'title': '待缴费',
    'studentFees': '学宿费',
    'total': '项，合计：',
    'tobe-pay': '应缴金额',
    'unpaid-amount': '未缴金额',
    'full-payment': '全额缴费',
    'payment-amount': '缴费金额',
    'reduction-amount': '减免金额',
    'batch-name': '批次名称'
  },
  //  更多应用
  moreMpplications: {
    title: '全部应用',
    input: '服务太丰富？试试过滤搜索。'
  },
  //  应用详情
  commonPay: {
    'title1': '缴费信息',
    'payAmount': '缴费金额',
    'payActual': '实际支付金额',
    'payTotal': '金额总计',
    'customerForm': '填报信息',
    'customAmount': '自定义金额',
    'discountAmount': '折扣金额'
  },
  //  离校退费
  leaveRefund: {
    'title': '离校退费',
    'batchNumber': '批次号',
    'studentNumber': '学号',
    'name': '姓名',
    'refundAmount': '退费金额',
    'transferStatus': '转账状态',
    'processStatus': '流程状态',
    'applyRefund': '申请退费'
  },
  //  离校退费账户
  leavePay: {
    'title': '请选择收款账户'
  },
  //  订单详情
  orderDetail: {
    'title': '订单详情',
    'orderInformation': '订单信息',
    'orderNumber': '订单号',
    'submissionTime': '提交时间',
    'orderAmount': '订单金额',
    'paymentInformation': '支付信息',
    'paymentTime': '支付时间',
    'paymentMethod': '支付方式',
    'paymentItems': '缴费项目',
    'entryName': '项目名称',
    'closeOrder': '关闭订单',
    'toPay': '去支付',
    'num': '批次名称'
  },
  //  person
  personOrder: {
    'title': '选择缴费方式',
    'paymentAmount': '付款金额'
  },
  //  趣味账单
  funnyBill: {
    'title': '账单',
    'monthTotal': '本月共',
    'total': '次缴费，合计：',
    'mostUse': '使用次数最多',
    'totalPay': '累计金额最多',
    'singlePay': '单笔金额最多',
    'payItem': '缴费项目请款',
    'unit': '单位：元',
    'mounth': '每月缴费情况'
  },
  //  修改密码
  password: {
    'title': '修改密码',
    'old': '原密码',
    'oldInput': '填写原密码',
    'new': '新密码',
    'newInput': '填写新密码',
    'check': '确认新密码',
    'checkInput': '再次填写确认',
    'confirmModification': '确认修改'
  },
  //  退款账户管理
  refundApplication: {
    'title': '退款账户管理',
    'addNew': '添加新的退款账号'
  },
  //  新增退款账户管理
  addApplication: {
    'type': '账户类型',
    'typeInput': '请选择账户类型',
    'accountInformation': '账户信息',
    'name': '收款人姓名',
    'nameInput': '请填写收款人姓名',
    'ali': '账号',
    'aliInput': '请填写账号',
    'bank': '开户行',
    'bankInput': '请填写开户行',
    'msg': '此操作将永久删除该内容, 是否继续?',
    'cancel': '已取消删除',
    'sure': '删除成功',
    'idNum': '身份证号',
    'openBank': '开户行',
    'idNumInput': '请填写身份证号',
    'openBankInput': '请填写开户行',
    'idNumError': '身份证号格式不正确'
  },
  //  报名缴费
  signUp: {
    'title': '报名缴费'
  },
  //  报名登录
  signLogin: {
    'title': '报名登录',
    'partTitle': '用户信息',
    'register': '还没有缴费平台账号？现在注册',
    'changeUser': '切换用户'
  },
  //  报名注册
  signReg: {
    'title': '报名注册',
    'btn': '已有缴费平台账号？现在登录'
  },
  //  查询缴费
  searchPay: {
    'title': '查询缴费',
    'input': '请输入关键字',
    'fix': '修改缴费金额'
  },
  //  缴费详情
  searchDetail: {
    'title': '缴费详情',
    'billAmount': '账单金额',
    'paid': '已缴金额',
    'deduction': '减免金额',
    'this': '本次缴费'
  },
  // 学费住宿费
  tuition: {
    'title': '学费住宿费'
  },
  // 银行卡信息查询
  bankAccount: {
    'queryInfo': '银行卡信息查询',
    'customerNumber': '客户号',
    'currencyType': '币种',
    'cashFlag': '钞汇标志',
    'accountOpenInstitutionCode': '账户开户机构号',
    'accountOpenName': '开户行名称',
    'credentialType': '证件类型',
    'credentialNumber': '证件号码',
    'customerName': '客户名称',
    'accountBalance': '账户余额',
    'availableBalance': '可用余额',
    'bankAccount': '银行账号',
    'fillAccountNo': '请输入银行卡号'
  }
}
export default cn
