import Vue from 'vue'
import DateTimeFormat from 'p-datetime'
/** *********************
 * 全局自定义内容        *
 ***********************/
/**
 * 绑定子页面打开新的tab页全局事件
 * @param {object} _this 当前页面的上下文
 * @param {object} tabItem tab的页面对象{rountPath:router的path,name:tab的name}
 * @param {object} routerItem $router的跳转参数,与this.$router.push的参数内容一致
 */
Vue.prototype.$switchTab = (_this, tabItem, routerItem) => {
  const arr = _this.$store.state.tabList
  let has = false
  for (const item of arr) {
    if (item.rountPath === tabItem.rountPath) {
      has = true
    }
  }
  if (!has) {
    arr.push(tabItem)
  }
  _this.$store.commit('setDefaultActive', tabItem.rountPath)
  _this.$store.commit('setTabList', arr)
  _this.$router.push(routerItem)
}
/**
 * 判断json为空
 */
Vue.prototype.$isEmptyJSON = (data) => {
  const arr = Object.keys(data)
  if (arr.length === 0) {
    return true
  } else {
    return false
  }
}
/**
 * 删除当前的tab
 */
Vue.prototype.$delActiveTab = (_this, rountPath) => {
  let arr = _this.$store.state.tabList
  arr = arr.filter(item => {
    if (item.rountPath === rountPath) {
      return false
    } else {
      return true
    }
  })
  _this.$store.commit('setTabList', arr)
}
/**
 * 全局日期转换api
 */
Vue.prototype.$formatDate = (date, formatStr) => {
  let d
  if (date instanceof Date) {
    d = date
  } else {
    d = new Date(date)
  }
  const d1 = new DateTimeFormat({
    datetime: d,
    format: formatStr
  })
  return d1.getDate()
}
