import request from '@/utils/request'
export function getProjectTypeTreeList(schoolCode) {
  return request({
    url: 'pay/pay/projectType/getProjectTypeTreeList/' + schoolCode,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      return data
    }]
  })
}

export function saveProjectTypeInfo(data) {
  return request({
    url: 'pay/pay/projectType',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}
export function updateProjectTypeInfo(data) {
  return request({
    url: 'pay/pay/projectType',
    method: 'put',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}
export function getProjectType(id) {
  return request({
    url: 'pay/pay/getProjectType/' + id,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function deleteProjectTypeInfo(id) {
  return request({
    url: 'pay/pay/projectType/deleteProjectType/' + id,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
export function getProjectTypeAndProjectInfoListInit(schoolCode) {
  return request({
    url: 'pay/pay/cos/merchant/getProjectTypeAndProjectInfoListInit/' + schoolCode,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
export function getProjectTypeAndProjectInfoList(parentId) {
  return request({
    url: 'pay/pay/cos/merchant/getProjectTypeAndProjectInfoList/' + parentId,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

