import Vue from 'vue'
import './plugins'
import './plugins/vant'
import App from './App.vue'
// import 'lib-flexible/flexible'
// import './http'
import common from './utils/common'// 封装工具类
import router from './router'
import store from './store'
import moment from 'moment/moment'
import VTop from './components/backToTop/ToTop.vue'
import './icons' // icon
import 'amfe-flexible'

// 手机console测试
// start
// import Vconsole from 'vconsole'
// const vConsole = new Vconsole()
// Vue.use(vConsole)
// end

import i18n from './i18n/i18n'
import { Local } from './i18n/lang/changeVant'
Vue.prototype.$Local = Local

import Router from 'vue-router'
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}
// 引入echarts
import echarts from 'echarts'
Vue.prototype.$echarts = echarts
import 'echarts/theme/macarons.js'
// 全局注册 main.js
import SignCanvas from 'sign-canvas'
Vue.use(SignCanvas)
// import Navigation from 'vue-navigation'
// Vue.use(Navigation, { router }) // router为路由文件

Vue.config.productionTip = false
const homeScrollTop = 0
Vue.prototype.$homeScroll = homeScrollTop

// import BScroll from 'better-scroll'
// Vue.use(BScroll);
// 全局引入UI库 BScroll
import '@/assets/resetui.css'
// 引入基础css
import '@/assets/main.css'
// 全局引入UI库 vant
import '@/plugins/vant'
// 全局引入rem
// import '@/config/rem'
// 全局引入 Reset-css
import 'reset-css'
// 注册为全局组件
Vue.component('v-top', VTop)

// 日期格式化-yyyy-MM-dd HH:mm:ss
Vue.filter('moment', function(value, formatString) {
  formatString = formatString || 'YYYY/MM/DD HH:mm:ss'
  return moment(value).format(formatString)
})
// 日期格式化-yyyy-MM-dd
Vue.filter('momentDay', function(value, formatString) {
  formatString = formatString || 'YYYY/MM/DD'
  // console.log(value)
  if (value === null) {
    return null
  } else {
    return moment(value).format(formatString)
  }
})

global.$vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

Vue.prototype.common = common
