// en.js
const en = {
  //  通用
  main: {
    'accountLogin': 'Account login',
    'return': 'return',
    'next': 'next',
    'campus-pay-cost': 'Campus pay cost',
    'more': 'more',
    'reset': 'reset',
    'screen': 'screen',
    'no-more': 'There is no more',
    'home': 'home',
    'order': 'order',
    'mine': 'mine',
    'selected': 'selected',
    'inputMustWrite': 'Item cannot be empty',
    'save': 'save',
    'name': 'name',
    'account': 'account',
    'delete': 'delete',
    'tips': 'Tips',
    'confirm': 'confirm',
    'certificateNumber': 'Certificate number',
    'money': 'Amount of money',
    'pay': 'Pay',
    'combinedPayment': 'Combined Payment'
  },
  // 提示
  msg: {
    'login': 'Please fill in the login information',
    'writeCode': 'Fill in the verification code',
    'wrongCode': 'The verification code is incorrect',
    'noNull': 'Item cannot be empty',
    'loadSuccess': 'Loading succeeded',
    'loginSuccess': 'Login successful',
    'pwdNull': 'Password cannot be empty',
    'pwdSame': 'The two passwords do not match',
    'pwdLength': 'The length is between 6 and 20 characters',
    'pwdSize': 'The password should at least include: numbers, characters and letters',
    'amountOver': 'The payment amount cannot be greater than or equal to the unpaid amount',
    'selectPaid': 'Please select the record to be paid',
    'partPaid': 'Partial payment cannot be 0 or empty',
    'selectItem': 'Please select a refund item',
    'selectAccount': 'Please select collection account',
    'refundSuccess': 'Refund successful',
    'selectMethod': 'Please select payment method',
    'updateWrong': 'Update order exception',
    'saveSuccess': 'Saved successfullys',
    'chooseSchool': 'Please choose a school',
    'writePwd': 'Please fill in the password',
    'truePhone': 'Please input the correct mobile phone number',
    'keyEmpty': 'Keyword cannot be empty'
  },
  //  登录页
  login: {
    'title1': 'Welcome to',
    'title2': 'Campus pay cost',
    'user-name': 'User name',
    'idserial': 'idserial',
    'psw': 'Passowrd',
    'v-code': 'Verification code',
    'login': 'Login',
    'register': 'Register',
    'status': 'Campus identity authentication',
    'fee': 'Registration fee',
    'change': 'Change login mode？',
    'quickly-pay': 'quicklyPay',
    'payQuery': 'payQuery'
  },
  //  注册页
  register: {
    'title': 'Register',
    'school': 'School：',
    'choose-school': 'Choose the school',
    'account': 'Account：',
    'fill-account': 'Fill in the account',
    'psw': 'Password：',
    'psw-input': '6-20place with figure letter character',
    'psw-confirm': 'Affirm psw：',
    'psw-input2': 'Please fill in the password again',
    'tel': 'Telephone：',
    'tel-input': 'Please fill in the mobile number'
  },
  //  首页
  home: {
    'characteristic': 'Special service',
    'search-pay': 'Query the pay',
    'search-des': '',
    'school-leave': 'Refund to school',
    'leave-title': '',
    'news': 'Message center'
  },
  //  个人中心
  person: {
    'title': 'Personal center',
    'account': 'Account：',
    'name': 'Name：',
    'id': 'Student id：',
    'college': 'College：',
    'change-password': 'Change Password',
    'unbundling': 'Unbinding third party accounts',
    'queryAccountBalance': 'Query Balance Of Account',
    'toast-notifier': 'Toast Notifier',
    'invoice-title': 'Manage invoice header',
    'request-parcel': 'Make an invoice',
    'user-guide': 'User guide',
    'refund-account': 'Manage refund account',
    'fun-bill': 'Funny bill',
    'log-out': 'Log out'
  },
  // 解绑
  unbundling: {
    'bottom-title': 'Third party account bound',
    'btn': 'Unbundling'
  },
  //  订单中心
  order: {
    'title': 'Order form',
    'filter-query': 'Filter query',
    'start-data': 'Start data',
    'data-input': 'Please select start time',
    'data-window': 'Select the time',
    'end-data': 'End date',
    'end-input': 'Please select end time',
    'payment-items': 'Payment items',
    'payment-input': 'Please select payment item',
    'order-status': 'Order status',
    'order-input': 'Please select order status',
    'key-word': 'Keywords',
    'key-input': 'Please enter keywords'
  },
  //  待缴费
  pay: {
    'title': 'To be paid',
    'studentFees': 'School and lodging expenses Project',
    'total': 'item，total：',
    'tobe-pay': 'Amount to be paid',
    'unpaid-amount': 'Unpaid amount',
    'reduction-amount': 'Reduction amount',
    'full-payment': 'Full payment',
    'payment-amount': 'Payment amount',
    'batch-name': 'Batch name'
  },
  //  更多应用
  moreMpplications: {
    title: 'All applications',
    input: 'Search for what you want'
  },
  //  应用详情
  commonPay: {
    'title1': 'Payment information',
    'payAmount': 'Payment amount',
    'payActual': 'Actual payment',
    'payTotal': 'Customer Form',
    'customerForm': 'Customer Form',
    'customAmount': 'Custom amount',
    'discountAmount': 'Discount amount'
  },
  //  离校退费
  leaveRefund: {
    'title': 'School leave refund',
    'batchNumber': 'Batch number',
    'studentNumber': 'Student number',
    'name': 'Name',
    'refundAmount': 'Refund amount',
    'transferStatus': 'Transfer status',
    'processStatus': 'Process status',
    'applyRefund': 'Apply for refund'
  },
  //  离校退费账户
  leavePay: {
    'title': 'Select collection account'
  },
  //  订单详情
  orderDetail: {
    'title': 'Order details',
    'orderInformation': 'Order information',
    'orderNumber': 'Order number',
    'submissionTime': 'Submission time',
    'orderAmount': 'Order amount',
    'paymentInformation': 'Payment information',
    'paymentTime': 'Payment time',
    'paymentMethod': 'Payment method',
    'paymentItems': 'Payment items',
    'entryName': 'Entry name',
    'closeOrder': 'Close order',
    'toPay': 'To pay',
    'num': 'Batch'
  },
  //  person
  personOrder: {
    'title': 'Select payment method',
    'paymentAmount': 'Payment amount'
  },
  //  趣味账单
  funnyBill: {
    'title': 'Bill',
    'monthTotal': 'This month',
    'total': 'pay，total：',
    'mostUse': 'Most used',
    'totalPay': 'Total paid in',
    'singlePay': 'Single paid in',
    'payItem': 'Application for payment items',
    'unit': 'Unit yuan',
    'mounth': 'Monthly payment'
  },
  //  修改密码
  password: {
    'title': 'Change Password',
    'old': 'Original pwd',
    'oldInput': 'Fill in the original password',
    'new': 'new pwd',
    'newInput': 'Fill in the new password',
    'check': 'Confirm pwd',
    'checkInput': 'confirmation again',
    'confirmModification': 'Confirm modification'
  },
  //  退款账户管理
  refundApplication: {
    'title': 'Refund account management',
    'addNew': 'Add a new refund account'
  },
  //  新增退款账户管理
  addApplication: {
    'type': 'Account type',
    'typeInput': 'Please select account type',
    'accountInformation': 'Account information',
    'name': 'Name of payee',
    'nameInput': 'Please fill in the payee\'s name',
    'ali': 'Alipay account',
    'aliInput': 'Please fill in the Alipay account',
    'bank': 'Bank of deposit',
    'bankInput': 'Please fill in the account bank',
    'msg': 'This operation will permanently delete the content. Do you want to continue',
    'cancel': 'Cancel deletion',
    'sure': 'Deletion succeeded'
  },
  //  报名缴费
  signUp: {
    'title': 'Registration fee'
  },
  //  报名登录
  signLogin: {
    'title': 'Sign up',
    'partTitle': 'User information',
    'register': 'register',
    'changeUser': 'Switch users'
  },
  //  报名注册
  signReg: {
    'title': 'Registration',
    'btn': 'Sign in'
  },
  //  查询缴费
  searchPay: {
    'title': 'Inquiry and payment',
    'input': 'Please enter keywords',
    'fix': 'Modify payment amount'
  },
  //  缴费详情
  searchDetail: {
    'title': 'Payment details',
    'billAmount': 'Bill amount',
    'paid': 'Amount paid',
    'deduction': 'Deduction amount',
    'this': 'This payment'
  },
  // 学费住宿费
  tuition: {
    'title': 'Tuition and accommodation'
  },
  // 银行卡信息查询
  bankAccount: {
    'queryInfo': 'Query Bank Information',
    'customerNumber': 'Customer Number',
    'currencyType': 'Currency Type',
    'cashFlag': 'Cash Flag',
    'accountOpenInstitutionCode': 'Account Open Institution Code',
    'accountOpenName': 'Account Open Name',
    'credentialType': 'Credential Type',
    'credentialNumber': 'Credential Number',
    'customerName': 'Customer Name',
    'accountBalance': 'Account Balance',
    'availableBalance': 'Available Balance',
    'bankAccount': 'Bank Account',
    'fillAccountNo': 'Please fill in the Bank Account Number'
  }
}
export default en
