import { login, quicklyLogin, logout, getInfo } from '@/api/user'
import { getToken, setToken, removeToken, setLoginStatus, getLoginStatus, removeLoginStatus } from '@/utils/auth'
import store from '@/store'

const state = {
  token: getToken(),
  accountNum: '',
  name: '',
  userId: '',
  idserial: '',
  avatar: '',
  opTel: '',
  opEmail: '',
  roles: [],
  // 第一次加载菜单时用到
  loadMenus: false,
  // 记录登录状态
  loginStatus: getLoginStatus(),
  // 记录按钮权限
  btns: [],
  schoolCode: '',
  // 身份证号
  idNum: ''
}

const mutations = {
  SET_ACCOUNTNUM: (state, accountNum) => {
    state.accountNum = accountNum
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_USER_ID: (state, userId) => {
    state.userId = userId
  },
  SET_IDSERIAL: (state, idserial) => {
    state.idserial = idserial
  },
  SET_USER_TEL: (state, tel) => {
    state.tel = tel
  },
  SET_USER_EMAIL: (state, email) => {
    state.email = email
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_LOAD_MENUS: (state, loadMenus) => {
    state.loadMenus = loadMenus
  },
  SET_LOGIN_STATUS: (state, loginStatus) => {
    state.loginStatus = loginStatus
  },
  SET_BTNS: (state, btns) => {
    state.btns = btns
  },
  SET_SCHOOLCODE: (state, schoolCode) => {
    state.schoolCode = schoolCode
  },
  SET_IDNUM: (state, idNum) => {
    state.idNum = idNum
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password, loginStatus, accountType, schoolCode } = userInfo
    return new Promise((resolve, reject) => {
      login({ accountNum: username.trim(), password: password, dataSource: store.state.settings.dataSource, schoolCode: schoolCode, accountType: accountType }).then(response => {
        const { data } = response
        commit('SET_TOKEN', data.token)
        // 存账号
        commit('SET_ACCOUNTNUM', username)
        localStorage.setItem('accountNum', username)
        setToken(data.token, loginStatus)
        // 第一次加载菜单时用到， 具体见 src 目录下的 permission.js
        commit('SET_LOAD_MENUS', true)
        // 记录登录状态
        commit('SET_LOGIN_STATUS', loginStatus)
        setLoginStatus(loginStatus)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  quicklyLogin({ commit }, userInfo) {
    const { username, loginStatus, accountType, schoolCode } = userInfo
    return new Promise((resolve, reject) => {
      quicklyLogin({ accountNum: username.trim(), dataSource: store.state.settings.dataSource, schoolCode: schoolCode, accountType: accountType }).then(response => {
        const { data } = response
        commit('SET_TOKEN', data.token)
        // 存账号
        commit('SET_ACCOUNTNUM', username)
        localStorage.setItem('accountNum', username)
        setToken(data.token, loginStatus)
        // 第一次加载菜单时用到， 具体见 src 目录下的 permission.js
        commit('SET_LOAD_MENUS', true)
        // 记录登录状态
        commit('SET_LOGIN_STATUS', loginStatus)
        setLoginStatus(loginStatus)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  // 统一身份登录
  qrcodeLogin({ commit }, params) {
    const { token } = params
    return new Promise(resolve => {
      commit('SET_TOKEN', token)
      setToken(token, false)
      // 存账号
      commit('SET_ACCOUNTNUM', '000000000')
      localStorage.setItem('accountNum', '000000000')
      // 第一次加载菜单时用到， 具体见 src 目录下的 permission.js
      commit('SET_LOAD_MENUS', true)
      // 记录登录状态
      commit('SET_LOGIN_STATUS', false)
      setLoginStatus(false)
      resolve()
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token).then(response => {
        const { data } = response
        if (!data) {
          reject('Verification failed, please Login again.')
        }
        data.avatar = data.headimgUrl
        data.userId = data.id
        const { roles, name, avatar, userId, tel, email, idserial } = data
        localStorage.setItem('idserial', idserial)
        // console.log('look', data)
        // roles must be a non-empty array
        if (!roles || roles.length <= 0) {
          // reject('getInfo: roles must be a non-null array!')
          // 如果没有任何权限，则赋予一个默认的权限，避免请求死循环
          commit('SET_ROLES', 'SET_ROLES', ['ROLE_SYSTEM_DEFAULT'])
        } else {
          commit('SET_ROLES', roles)
        }
        commit('SET_NAME', name)
        commit('SET_IDSERIAL', idserial)
        commit('SET_AVATAR', avatar)
        commit('SET_USER_ID', userId)
        commit('SET_USER_TEL', tel)
        commit('SET_USER_EMAIL', email)
        // 记录登录状态
        commit('SET_LOGIN_STATUS', state.loginStatus)
        if (!localStorage.accountNum) {
          localStorage.setItem('accountNum', idserial)
        }
        resolve(data)
      }).catch(error => {
        // eslint-disable-next-line no-undef
        reject(error)
      })
    })
  },

  // 退出
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        removeToken()
        removeLoginStatus()
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  // 设置buttons
  setBtns({ commit }, buttons) {
    commit('SET_BTNS', buttons)
  },
  // 长时间未操作自动退出
  autoLogout({ commit }) {
    return new Promise((resolve) => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      removeLoginStatus()
      resolve()
    })
  },
  updateLoadMenus({ commit }) {
    return new Promise(() => {
      commit('SET_LOAD_MENUS', false)
    })
  },

  // 移除token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      removeLoginStatus()
      resolve()
    })
  },
  // 重新刷新token
  refreshToken({ commit }, refreshToken) {
    return new Promise(resolve => {
      commit('SET_TOKEN', refreshToken)
      setToken(refreshToken)
      resolve()
    })
  }
}
export const setUserInfo = (res, commit) => {
  // 如果没有任何权限，则赋予一个默认的权限，避免请求死循环
  if (res.roles.length === 0) {
    commit('SET_ROLES', ['ROLE_SYSTEM_DEFAULT'])
  } else {
    commit('SET_ROLES', res.roles)
  }
  commit('SET_USER', res)
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}

