import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
// import Layout from '@/layout'

/**
//当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
hidden: true // (默认 false)

//当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
redirect: 'noRedirect'

//当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
//只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
//若你想不管路由下面的 children 声明的个数都显示你的根路由
//你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
alwaysShow: true

name: 'router-name' //设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
meta: {
  roles: ['admin', 'editor'] //设置该路由进入的权限，支持多个权限叠加
  title: 'title' //设置该路由在侧边栏和面包屑中展示的名字
  icon: 'svg-name' //设置该路由的图标
  noCache: true //如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
  breadcrumb: false // 如果设置为false，则不会在breadcrumb面包屑中显示
}
**/
// 代表那些不需要动态判断权限的路由
export const constantRouterMap = [
  // {
  //   path: '/login',
  //   name: 'login',
  //   meta: { title: '登录', noCache: true },
  //   component: resolve => require(['../views/login/index.vue'], resolve),
  //   hidden: true
  // },
  // {
  //   path: '/404',
  //   component: resolve => require(['../views/404'], resolve),
  //   hidden: true
  // },
  // {
  //   path: '/500',
  //   component: resolve => require(['../views/500'], resolve),
  //   hidden: true
  // },
  // {
  //   path: '/redirect',
  //   component: Layout,
  //   hidden: true,
  //   children: [
  //     {
  //       path: '/redirect/:path*',
  //       component: resolve => require(['../views/redirect/index'], resolve)
  //     }
  //   ]
  // },
  // {
  //   path: '/',
  //   component: Layout,
  //   redirect: 'dashboard',
  //   children: [
  //     {
  //       path: 'dashboard',
  //       // component: () => import('@/views/dashboard/index'),
  //       component: resolve => require(['../views/dashboard/index'], resolve),
  //       name: '首页',
  //       meta: { title: '首页', icon: '首页', noCache: false, affix: true }
  //     }
  //   ]
  // },
  // // 个人中心
  // {
  //   path: '/personCenter',
  //   component: Layout,
  //   hidden: true,
  //   redirect: 'noredirect',
  //   children: [
  //     {
  //       path: 'personCenter',
  //       // component: () => import('@/views/personCenter'),
  //       component: resolve => require(['../views/personCenter'], resolve),
  //       name: 'personCenter',
  //       meta: { title: '个人中心', icon: 'user' }
  //     }
  //   ]
  // },
  // {
  //   path: '/project/batchDetail',
  //   component: Layout,
  //   hidden: true,
  //   redirect: 'noredirect',
  //   children: [
  //     {
  //       path: 'detail',
  //       // component: () => import('@/views/project/batchConfig/detail'),
  //       component: resolve => require(['../views/project/batchConfig/detail'], resolve),
  //       name: 'detail',
  //       meta: { title: '缴费项目批次明细', icon: 'user' }
  //     }
  //   ]
  // },
  // {
  //   path: '/project/nameList',
  //   component: Layout,
  //   hidden: true,
  //   redirect: 'noredirect',
  //   children: [
  //     {
  //       path: 'nameList',
  //       // component: () => import('@/views/project/baseData/nameList'),
  //       component: resolve => require(['../views/project/baseData/nameList'], resolve),
  //       name: 'nameList',
  //       meta: { title: '缴费名单批次管理', icon: 'user' }
  //     }
  //   ]
  // },
  // {
  //   path: '/project/model',
  //   component: Layout,
  //   hidden: true,
  //   redirect: 'noredirect',
  //   children: [
  //     {
  //       path: 'meeting',
  //       // component: () => import('@/views/project/apply/meetingFees'),
  //       component: resolve => require(['../views/project/apply/meetingFees'], resolve),
  //       name: 'meeting',
  //       meta: { title: '会议费申请', icon: 'user' }
  //     },
  //     {
  //       path: 'registration',
  //       // component: () => import('@/views/project/apply/registration'),
  //       component: resolve => require(['../views/project/apply/registration'], resolve),
  //       name: 'registration',
  //       meta: { title: '报名费申请', icon: 'user' }
  //     },
  //     {
  //       path: 'common',
  //       // component: () => import('@/views/project/apply/common'),
  //       component: resolve => require(['../views/project/apply/common'], resolve),
  //       name: 'common',
  //       meta: { title: '通用类', icon: 'user' }
  //     },
  //     {
  //       path: 'nameImport',
  //       // component: () => import('@/views/project/apply/nameImport'),
  //       component: resolve => require(['../views/project/apply/nameImport'], resolve),
  //       name: 'nameImport',
  //       meta: { title: '名单导入类', icon: 'user' }
  //     },
  //     {
  //       path: 'projectEdit',
  //       // component: () => import('@/views/project/apply/edit'),
  //       component: resolve => require(['../views/project/apply/edit'], resolve),
  //       name: 'projectEdit',
  //       meta: { title: '项目申请修改', icon: 'user' }
  //     }
  //   ]
  // },
  // {
  //   path: '/project/pay',
  //   component: Layout,
  //   hidden: true,
  //   redirect: 'noredirect',
  //   children: [
  //     {
  //       path: 'meetingPay',
  //       // component: () => import('@/views/student/waitingPay'),
  //       component: resolve => require(['../views/project/pay/meeting'], resolve),
  //       name: 'meetingPay',
  //       meta: { title: '会议费支付', icon: 'user' }
  //     }
  //   ]
  // },
  // {
  //   path: '/project/pay',
  //   component: Layout,
  //   hidden: true,
  //   redirect: 'noredirect',
  //   children: [
  //     {
  //       path: 'commonPay',
  //       // component: () => import('@/views/student/waitingPay'),
  //       component: resolve => require(['../views/project/pay/meeting'], resolve),
  //       name: 'commonPay',
  //       meta: { title: '通用费用支付', icon: 'user' }
  //     }
  //   ]
  // },
  // {
  //   path: '/project/pay',
  //   component: Layout,
  //   hidden: true,
  //   redirect: 'noredirect',
  //   children: [
  //     {
  //       path: 'searchPay',
  //       // component: () => import('@/views/student/waitingPay'),
  //       component: resolve => require(['../views/project/pay/searchPay'], resolve),
  //       name: 'searchPay',
  //       meta: { title: '查询类缴费', icon: 'user' }
  //     }
  //   ]
  // },
  // {
  //   path: '/pay/order',
  //   component: Layout,
  //   hidden: true,
  //   redirect: 'noredirect',
  //   children: [
  //     {
  //       path: 'orderlist',
  //       // component: () => import('@/views/student/waitingPay'),
  //       component: resolve => require(['../views/project/pay/orderList'], resolve),
  //       name: 'orderlist',
  //       meta: { title: '订单列表', icon: 'user' }
  //     }
  //   ]
  // },
  // {
  //   path: '/pay/order',
  //   component: Layout,
  //   hidden: true,
  //   redirect: 'noredirect',
  //   children: [
  //     {
  //       path: 'nameListOrder',
  //       // component: () => import('@/views/student/waitingPay'),
  //       component: resolve => require(['../views/project/pay/nameListOrder'], resolve),
  //       name: 'nameListOrder',
  //       meta: { title: '订单详情', icon: 'user' }
  //     }
  //   ]
  // },
  // {
  //   path: '/pay/funBill',
  //   component: Layout,
  //   hidden: true,
  //   redirect: 'noredirect',
  //   children: [
  //     {
  //       path: 'funBill',
  //       // component: () => import('@/views/student/waitingPay'),
  //       component: resolve => require(['../views/dashboard/funBill'], resolve),
  //       name: 'funBill',
  //       meta: { title: '趣味账单', icon: 'user' }
  //     }
  //   ]
  // },
  // {
  //   path: '/pay/order',
  //   component: Layout,
  //   hidden: true,
  //   redirect: 'noredirect',
  //   children: [
  //     {
  //       path: 'orderinfo',
  //       // component: () => import('@/views/student/waitingPay'),
  //       component: resolve => require(['../views/project/pay/orderinfo'], resolve),
  //       name: 'orderinfo',
  //       meta: { title: '订单详情', icon: 'user' }
  //     }
  //   ]
  // },
  // {
  //   path: '/student/model',
  //   component: Layout,
  //   hidden: true,
  //   redirect: 'noredirect',
  //   children: [
  //     {
  //       path: 'waitingPay',
  //       // component: () => import('@/views/student/waitingPay'),
  //       component: resolve => require(['../views/student/waitingPay'], resolve),
  //       name: 'waitingPay',
  //       meta: { title: '待缴费列表', icon: 'user' }
  //     }
  //   ]
  // },
  // {
  //   path: '/pay/qRcode',
  //   component: Layout,
  //   hidden: true,
  //   redirect: 'noredirect',
  //   children: [
  //     {
  //       path: 'payQrcode',
  //       // component: () => import('@/views/student/waitingPay'),
  //       component: resolve => require(['../views/project/pay/payQrCode'], resolve),
  //       name: 'payQrcode',
  //       meta: { title: '请扫码支付', icon: 'user' }
  //     }
  //   ]
  // },
  // {
  //   path: '/project/pay',
  //   component: Layout,
  //   hidden: true,
  //   redirect: 'noredirect',
  //   children: [
  //     {
  //       path: 'payType',
  //       // component: () => import('@/views/student/waitingPay'),
  //       component: resolve => require(['../views/project/pay/payType'], resolve),
  //       name: 'payType',
  //       meta: { title: '选择支付方式', icon: 'user' }
  //     }
  //   ]
  // }
]

export default new Router({
  // mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})
