/* eslint-disable no-undef */
<!-- 底部菜单栏   接口拿回签名 -->
<template>
  <div style="position: fixed;bottom: 0;left: 0;">
    <!-- 底部菜单栏 开始 -->
    <van-tabbar id="app" route style="height:50px">
      <van-tabbar-item v-for="(item,index) in tabBarList" :key="index" @click="toPath(item,index)">
        <span>{{ item.name }}</span>
        <img
          slot="icon"
          :src="$route.path===item.path ? item.activeImg:item.normalImg"
        >
      </van-tabbar-item>
    </van-tabbar>
  </div>
  <!-- 底部菜单栏 结束 -->
</template>

<script>
import Vue from 'vue'
import {
  Tabbar,
  TabbarItem
} from 'vant'

Vue.use(Tabbar).use(TabbarItem)

export default {
  data() {
    return {
      demo: 0,
      tabBarList: [
        {
          name: this.$t('main.home'),
          path: '/home',
          normalImg: require('../../assets/images/mobileHome.svg'),
          activeImg: require('../../assets/images/mobileHomeChose.svg')
        },
        {
          name: this.$t('main.order'),
          path: '/orderList',
          normalImg: require('../../assets/images/reimbursementManagement.svg'),
          activeImg: require('../../assets/images/reimbursementManagementChose.svg')
        },
        // {
        //   name: this.$t('pay.title'),
        //   path: '/balanceInquiry',
        //   normalImg: require('../../assets/images/projectManagement.svg'),
        //   activeImg: require('../../assets/images/projectManagementChose.svg.svg')
        // },
        {
          name: this.$t('main.mine'),
          path: '/personCenter',
          normalImg: require('../../assets/images/uesr.svg'),
          activeImg: require('../../assets/images/userChose.svg')
        }
      ],
      activeIndex: 0
    }
  },
  methods: {
    toPath(item, index) {
      this.activeIndex = index
      this.$router.push({ path: item.path })
    }
  }
}
</script>

<style scoped>
  #app {
    padding-bottom: env(safe-area-inset-bottom);
  }

  .van-tabbar-item__icon img {
    height: 30px;
  }

  .callout {
    position: fixed;
    width: 80px;
    height: 80px;
    left: 25%;
    bottom: 30px;
    /* border: 1px solid #1890ff; */
    background: #fff;
    border-radius: 5px;
    /* margin-top: 15%; */
    border: 1px #c1d4fd solid;
    z-index: 10;
    /* box-shadow: 0 0 6px #dde9f3; */
  }

  .callout::before {
    content: "";
    position: absolute;
    top: 70px;
    right: 10px;
    padding: 8px;
    background: inherit;
    border: inherit;
    border-right: 0;
    border-bottom: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(225deg);
  }

  .callouttwo {
    position: fixed;
    width: 80px;
    height: 40px;
    left: 75%;
    bottom: 30px;
    border: 1px solid #c1d4fd;
    background: #fff;
    border-radius: 5px;
    /* margin-top: 15%; */
    z-index: 10;
    /* box-shadow: 0 0 6px #dde9f3; */
  }

  .callouttwo::before {
    content: "";
    position: absolute;
    top: 30px;
    right: 10px;
    padding: 8px;
    background: inherit;
    border: inherit;
    border-right: 0;
    border-bottom: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(225deg);
  }

  .calloutthree {
    position: fixed;
    width: 80px;
    height: 40px;
    left: 50%;
    bottom: 30px;
    border: 1px solid #c1d4fd;
    background: #fff;
    border-radius: 5px;
    /* margin-top: 15%; */
    z-index: 10;
    /* box-shadow: 0 0 6px #dde9f3; */
  }

  .calloutthree::before {
    content: "";
    position: absolute;
    top: 30px;
    right: 10px;
    padding: 8px;
    background: inherit;
    border: inherit;
    border-right: 0;
    border-bottom: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(225deg);
  }
</style>
